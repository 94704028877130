import { defineStore } from 'pinia';
import { UnwrapRef } from 'vue';
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import {api} from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { EnumsAdminPermission } from '../../generated/api';

interface IState {
  languageCode: string;
  darkMode: boolean;
  permissions: EnumsAdminPermission[];
  permissionsLoaded: boolean;
}

export const useUserStore = defineStore('user', {
  state: (): IState =>
    <IState>{
      languageCode: '',
      darkMode: false,
      permissions: [],
      permissionsLoaded: false,
    },
  getters: {
    getIsLoggedIn: (): UnwrapRef<boolean> => {
      const authStore = useAuthStore();
      return !!authStore.refreshToken;
    },
    getLanguageCode: (state) => state.languageCode,
    getIsDarkMode: (state) => state.darkMode,
    getPermissionsLoaded: (state) => state.permissionsLoaded,
  },
  actions: {
    async setLanguage(languageCode: string) {
      this.languageCode = languageCode;
      // Load locale messages
      if (!i18n.global.availableLocales.includes(languageCode)) {
        await loadLocaleMessages(i18n, languageCode);
      }
      // Set i18n language
      setI18nLanguage(i18n, languageCode);
    },
    async getPermissions() {
      if (!this.getIsLoggedIn) {
        return;
      }

      try {
        const { data: permissions } = await api.adminAuthGetPermissions();

        this.setPermissions(permissions.adminPermissions || []);

      } catch (error) {
        console.error('Error fetching permissions', error);
      }
    },
    setPermissions(permissions: EnumsAdminPermission[]) {
      this.permissions = permissions;
    },
    clear() {
        this.permissions = [];
    }
  },
  persist: {
    paths: ['languageCode'],
  },
});
